<template>
    <div>
        <div>
            <p class="text-center">
                We've sent a verification code to your email. Please check your inbox to proceed.
            </p>
        </div>
    <div style="display: flex; justify-content: center; margin-top: 16px;">
        <div>
            <div class="otp-inputs">
                <input
                v-for="(value, index) in codeOtp"
                :key="index"
                type="tel"
                v-model="codeOtp[index]"
                @input="moveToNext(index, $event)"
                @keydown="handleKeyDown($event, index)"
                @keypress="validateNumber"
                @paste="handlePaste($event)"
                @click="focusEnd($event)"
                :class="{'input-error': !codeOtp[index] && hasErrorRequired}"
                ref="input"
                />
            </div>
            <div style="margin-top: 6px;" v-if="timeRemaining == 0">
                <span v-if="isLoadingResendCode" style="color: gray !important;">Loading resend code....</span>
                <span v-else>Didn't get otp? <span @click="onClickResendCode" style="text-decoration: underline; color: blue !important; cursor: pointer;">Resend code.</span></span>
            </div>
            <div v-else style="margin-top: 6px;">
                <p class="text-center">
                    The OTP will expire in: {{ minutes }}:{{ seconds }}
                </p>
            </div>
            <div style="margin-top: 16px; display: flex; justify-content: center; margin-bottom: 16px;">
                <base-button @click="submitCode" :disabled="isLoadingVerify || isLoadingVerifyTwoFactorAuth">{{ isLoadingVerify || isLoadingVerifyTwoFactorAuth ? 'Loading...' : 'Verify' }}</base-button>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { Notification } from 'element-ui'

export default {
    props:{
        isTwoFactorAuth: {
            type: Boolean,
            default: false,
        },
        isLoadingVerifyTwoFactorAuth: {
            type: Boolean,
        },
        onLogin: {
            type: Function,
        },
        emailTwoFactorAuth: {
            type: String
        }
    },
    data() {
        return {
            codeOtp: ['', '', '', '', '', ''],
            timeRemaining: 60,
            intervalId: null,
            hasErrorRequired: false,
            isLoadingVerify: false,
            isLoadingResendCode: false,
        }
    },
    watch: {
        isTwoFactorAuth(newValue){
            if(newValue){
                this.timeRemaining = 60
                this.startTimer()
                this.codeOtp = ['', '', '', '', '', '']
                this.hasErrorRequired = false
                this.$refs.input[0].focus();
            }
        }
    },
    methods: {
        moveToNext(index, event) {
            const inputValue = event.target.value

            this.$set(this.codeOtp, index, inputValue.slice(-1));

            if (this.codeOtp[index] !== '' && index < this.codeOtp.length - 1) {
                this.$refs.input[index + 1].focus();
            }
        },
        focusEnd(event) {
            const input = event.target;
            input.setSelectionRange(input.value.length, input.value.length);
            input.focus();
        },
        handleKeyDown(event, index) {
            const key = event.key;

            if(this.isAllCodeFilled() && key === 'Enter'){
                this.submitCode()
            }
            

            if (key === 'Backspace') {
                if (this.codeOtp[index] === '' && index > 0) {
                this.$refs.input[index - 1].focus(); 
                this.codeOtp[index - 1] = ''; 
                } else {
                this.codeOtp[index] = ''; 
                }
            }

            if (key === 'ArrowLeft') {
                event.preventDefault();

                this.$nextTick(() => {
                    this.$refs.input[index].focus();
                    this.$refs.input[index].setSelectionRange(this.$refs.input[index].value.length, this.$refs.input[index].value.length);
                });
            }
        },
        validateNumber(event) {
            const key = event.key;
            if (!/^\d$/.test(key)) {
                event.preventDefault();
            }
        },
        startTimer() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            this.intervalId = setInterval(() => {
                if (this.timeRemaining > 0) {
                    this.timeRemaining--;
                } else {
                    clearInterval(this.intervalId);
                }
            }, 1000);
        },
        async onClickResendCode(){
            this.isLoadingResendCode = true
            await this.$store.dispatch('resendCode', {
                email: this.emailTwoFactorAuth,
                companyrootid: this.$global.idsys
            }).then(response => {
                if(response.result == 'error'){
                        Notification.error({
                        title: 'Error',
                        message: response.message,
                        customClass: 'error-notification', 
                    });
                } else {
                    this.codeOtp = ['', '', '', '', '', '']
                    this.hasErrorRequired = false
                    this.$refs.input[0].focus();
                    this.timeRemaining = 60
                    this.startTimer();
                    Notification.success({
                        title: 'Success',
                        message: response.message,
                    });
                }
            }, error => {
                Notification.error({
                    title: 'Error',
                    message: error.message,
                    customClass: 'error-notification', 
                });
            })
            this.isLoadingResendCode = false
        },
        async submitCode() {
            if (this.isAllCodeFilled()) {
                this.isLoadingVerify = true,
                this.hasErrorRequired = false;
                let code = '';
                this.codeOtp.forEach((value) => {
                    code += value;
                })
               await this.$store.dispatch('verifyLogin', {
                code,
                email: this.emailTwoFactorAuth,
               }).then(response => {
                if(response.result == 'error'){
                        Notification.error({
                        title: 'Error',
                        message: response.message,
                        customClass: 'error-notification', 
                    });
                    this.hasErrorRequired = true
                    this.isLoadingVerify = false
                } else {
                    this.onLogin()
                    this.isLoadingVerify = false
                }
               }, error => {
                    Notification.error({
                        title: 'Error',
                        message: error.message,
                        customClass: 'error-notification', 
                    });
                    this.isLoadingVerify = false
                    this.hasErrorRequired = true
               })
            } else {
                this.isLoadingVerify = false
                this.hasErrorRequired = true;
                Notification.error({
                   title: 'Error',
                   message: 'Please fill all the code fields.',
                   customClass: 'error-notification',
                });
            }
        },
        isAllCodeFilled() {
            return this.codeOtp.every(code => code.length === 1);
        },
        handlePaste(event) {
            const pasteData = event.clipboardData.getData('text');
            const pasteArray = pasteData.split('').filter(char => !isNaN(char)).slice(0, this.codeOtp.length);

            pasteArray.forEach((char, i) => {
                this.$set(this.codeOtp, i, char);
            });

            const lastFilledIndex = pasteArray.length - 1;
            if (lastFilledIndex < this.codeOtp.length - 1) {
                this.$refs.input[lastFilledIndex + 1].focus();
            }

            event.preventDefault();
        },
    },
    computed: {
        minutes() {
            return Math.floor(this.timeRemaining / 60).toString().padStart(2, '0');
        },
        seconds() {
            return (this.timeRemaining % 60).toString().padStart(2, '0');
        },
    },
    mounted() {
        this.$refs.input[0].focus();
        this.startTimer();
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
}
</script>

<style>
.otp-inputs {
  display: flex;
  gap: 8px;
}

.otp-inputs input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-inputs .input-error {
  border: 1px solid red;
}
</style>