<template>
    <div class='row user-profile-main'>
        <div class='user-profile-info-wrapper col-12'>
            <div class="user-profile-picture-wrapper"><img :src="images.avatar" alt="preview">
            </div>
            <div class="user-name-wrapper">
              <span class="user-name">{{ userdata.name }}</span>
              <span style="opacity: 0.6">Manage your profile and account settings</span>
            </div>
        </div>
        <div class="col-12 mt-5">
          <div class="profile-setup-wrapper d-flex flex-column flex-md-row">

            <div class="">
                <div class="flex-md-column flex-row user-profile-section-selector-wrapper flex-wrap">
                  <div  v-for="(item, index) in profileTabs" :key="item.name" @click="changeStepProfile(item)">
                    <div v-if="index !== 2 || !isAdmin" :class="['user-profile-section-selector',{ '--active' :selectedStep.name == item.name}]">
                      <i :class="item.icon"></i> <span>{{ item.name }}</span>
                    </div>
                  </div>
                  <!-- <div class="user-profile-section-selector">
                    <i class="tim-icons icon-badge"></i> <span>Business Profile</span>
                  </div>
                  <div class="user-profile-section-selector" v-if="!isAdmin">
                    <i class="tim-icons icon-bank"></i> <span>>Payment Setup</span>
                  </div> -->
                </div>
            </div>
            <div class="user-setup-right-wrapper">
               <card>
                
                <first-step-v1
                   ref="step1"
                  v-if="selectedStep.step === 'step1'"
                  @on-validated="onStepValidated"
                ></first-step-v1>
                <second-step-v1
                  ref="step2"
                   v-if="selectedStep.step === 'step2'"
                  @on-validated="onStepValidated"
                 ></second-step-v1>
                  <change-password-step  v-if="selectedStep.step == 'step4'">
                    
                  </change-password-step>
               
                
                <base-button v-if="selectedStep.step != 'step4'" class="float-right mt-5" @click='updateChanges'>Save Changes</base-button>
               </card>
               <!-- <card v-if="selectedStep.step == 'step4'">
                <security-setting  v-if="selectedStep.step == 'step4'">
                    
                </security-setting>
               </card> -->
            </div>
          </div>
        </div>
      
    </div>
  </template>
  <script>
  import FirstStepV1 from './Includes/UserSetup/FirstStepV1.vue';
  import SecondStepV1 from './Includes/UserSetup/SecondStepV1.vue';
  import ChangePasswordStep from './Includes/UserSetup/ChangePasswordStep.vue';
  import SecuritySetting from './Includes/UserSetup/SecuritySetting.vue';
  import ThirdStep from './Includes/UserSetup/ThirdStep.vue';
  //import FourthStep from './Includes/UserSetup/FourthStep.vue';
  import FinishStep from './Includes/UserSetup/FinishStep.vue';
  import swal from 'sweetalert2';
  import { SimpleWizard, WizardTab } from 'src/components';
import BaseButton from '../../../../components/BaseButton.vue';
import CardSetting from './CardSetting.vue';
  
  export default {
    data() {
      return {
        wizardModel: {},
        isAdmin:false,
        profileTabs:[
          {
            name:'Personal Profile',
            icon:'tim-icons icon-single-02',
            step:'step1',
            
          },
          {
            name:'Business Profile',
            icon:'tim-icons icon-badge',
            step:'step2'
          },
          {
            name:'Payment Setup',
            icon:'tim-icons icon-bank',
            step:'step3'
          },
          {
            name:'Privacy & Security',
            icon:'tim-icons icon-lock-circle',
            step:'step4'
          }
        ],
        selectedStep:   {
            name:'Personal Profile',
            icon:'tim-icons icon-single-02',
            step:'step1',
            
          },
        questionnaireCompleted:false,
        userdata: [],
        btnFinishShow:true,
        initialCheck:true,
        btnFinishTxt: 'Go To General Settings',
         images: {
          regular: '',
          avatar: '',
        },
      };
    },
    components: {
      FirstStepV1,
      SecondStepV1,
      ChangePasswordStep,
      SecuritySetting,
      ThirdStep,
      //FourthStep,
      FinishStep,
      SimpleWizard,
      WizardTab,
        BaseButton,
        CardSetting
    },
    methods: {
      updateChanges(){
        this.validateStep(this.selectedStep.step)
      },
      changeStepProfile(item){
        if (item.step == 'step3' && this.userdata.customer_payment_id == '') {
          this.$router.push({name: 'Profile Setup'})
        }else{
          this.selectedStep = item
        }
      },
      validateStep(ref) {
        //console.log(ref);
        return this.$refs[ref].validate();
      },
      onStepValidated(validated, model) {
        //console.log(validated.FullName);
        this.wizardModel = { ...this.wizardModel, ...model };
        //console.log(this.wizardModel);
        //console.log(this.wizardModel.FullName);
      },
      finishstep() {
        //swal('Good job!', 'You clicked the finish button!', 'success');
        //alert('God Bless You!');
        this.initialCheck = false;
        this.CheckRoleModule();
      },
      CheckRoleModule() {              
        //console.log(user)
        var companyID = this.userdata.company_id;
        var roleid = this.userdata.role_id;
        this.userType = this.userdata.user_type;
        this.$sidebar.displaySidebar(true);
        
        if(this.userType == 'client') {
          this.$store.dispatch('checkUserSetupComplete', {
                usrID: this.userdata.id,
            }).then(response => {
                this.$global.checkClientModule(response.setupcomplete,response.accessmodule);
                //console.log(response);
                if(response.accessmodule['leadspeek']) {
                  if(!this.initialCheck) {
                    var lptype = response.accessmodule['leadspeek_type'];
                    lptype = lptype.split('|');
                    for(var i=0;i<lptype.length;i++) {
                      if (lptype[i] == 'local') {
                        document.location = '/' + this.userdata.leadlocalurl + '/dashboard';
                        return
                      }else{
                        document.location = '/' + this.userdata.leadlocatorurl + '/dashboard';
                      }
                    }
                    //this.$router.push({ name: this.userdata.leadlocalname });
                  }else{
                    //console.log('here');
                    $('#finishheader').html('Thank you for completing your profile!');
                    //$('#finishsubheader').html('Click "Go to Dashboard" button to start using the application.');
                  }
                }else{
                  //console.log('there');
                  //this.btnFinishShow = false;
                }
            },error => {
                
            });
        }else{
          this.$store.dispatch('GetRoleList', {
                companyID: companyID,
                getType:'getrolemodule',
                roleID:roleid,
                usrID: this.userdata.id,
            }).then(response => {
                //console.log(user);
                //this.rolemodulelist = response;
                //this.checkModuleRole(response);
                this.$global.checkModuleRole(response.modules,response.setupcomplete);
                if(!this.initialCheck) {
                  //this.$router.push({ name: 'LeadSpeek' })
                  this.$router.push({name: 'General Setting'})
                }else{
                    $('#finishheader').html('Thank you for completing your profile!');
                    //$('#finishsubheader').html('Click "Go to Dashboard" button to start using the application.');
                }
            },error => {
                
            });
        }
  
      },
    },
    computed:{
      profilePicture(){
        if(this.userdata && this.userdata.profile_pict === null || this.userdata.profile_pict === ''){
          return '/img/placeholder.jpg'
        }else{
          return this.userdata.profile_pict
        }
      }
    },
    mounted() {
      this.userdata = this.$store.getters.userData
 
      this.CheckRoleModule();
      //this.isAdmin = false;
      this.images.avatar = (this.userdata.profile_pict == '' || this.userdata.profile_pict == null)?'/img/placeholder.jpg':this.userdata.profile_pict;
     
      //console.log(this.userdata);
      this.isAdmin = false;
      this.questionnaireCompleted = true;
      if ((this.userdata.isAdmin == 'T' && this.userType == 'user') || this.userdata.customer_payment_id != '') {
        this.isAdmin = true;
      }
      if(this.userdata.questionnaire_setup_completed == 'T' || this.userdata.isAdmin == 'T' || this.userdata.user_type == 'user' || this.userdata.user_type == 'userdownline' || this.userdata.disable_client_add_campaign == 'T') {
        this.questionnaireCompleted = true;
      }
  
      if (this.userType == 'client') {
        this.btnFinishTxt = 'Go To Dashboard';
      }else{
        this.btnFinishTxt = 'Go To General Settings';
      }
      
    }
  };
  
  </script>
  <style lang="scss">
  .user-profile-main{
    margin-top: -32px;
  }
  .user-profile-info-wrapper{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap:8px;
    .user-name-wrapper{
      display: flex;
      flex-direction: column;
      .user-name{
        font-size: 24px;
        font-weight: 600;
  
        white-space: nowrap;         /* Prevents text from wrapping to the next line */
        overflow: hidden;            /* Hides the overflow text */
        text-overflow: ellipsis;     /* Adds the ellipsis (...) to indicate overflow */
        width: 250px;
      }
    }
  }
  .user-profile-picture-wrapper{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;
    img {
      height:100%;
      width:100%;
    }
  }
  .user-profile-main-wrapper{
    display: flex;
    gap: 40px;
    align-items: flex-start;
  }
  .user-profile-section-selector-wrapper{
    display: flex;
    /* flex-direction: column; */
    gap: 8px;
    /* max-width:242px; */
    width:100%;
    top: 40px;
    position: sticky;
    .user-profile-section-selector{
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      height: 40px;
      
      width:max-content;
      cursor: pointer;
      transition: 0.2s ease;
      gap:16px;
      &:hover{
        background:var(--btn-primary-color);
        color:var(--text-btn-black);
      }
      &.--active{
        background:var(--btn-primary-color);
        color:var(--text-btn-black);
      }
    }
  }
  .profile-setup-wrapper{
    gap:80px;
  }
  .profile-upload-wrapper{
    gap:36px;
  }
  .user-setup-right-wrapper{
    .form-group{
      .input-group-text{
        border-color:#D9D9D9;
      }
      input{
        border-color:#D9D9D9;
      }
    }
    .country-region-select-wrapper{
      select{
        font-size:0.80143rem;
        border-color:#D9D9D9;
        color:var(--primary-input-text-color)
      }
    }
  }
  </style>
  